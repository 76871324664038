<template>
  <loading-container :loading="isLoading || !ydoc">
    <async-form @keydown.enter.prevent @submit="submit" persist>
      <activity
        ref="activity"
        v-slot="{
          variableContext,
          viewMode,
          toggleSplitView,
          splitViewAvailable
        }"
        :activity="activity"
        :response="response"
        :is-overdue="isOverdue"
        :is-submitted="isSubmitted"
        :is-reopened="isReopened"
      >
        <sticky-header>
          <template v-if="hasAutosaveError" #above>
            <p class="response__autosave-error">
              <icon icon="triangle-exclamation" />
              <template
                v-if="['connecting', 'disconnected'].includes(connectionStatus)"
              >
                You are disconnected from the server, please check your internet
                connection or try again in a few mintues. Your work is not being
                saved.
              </template>
              <template v-else-if="'failing' === autosaveStatus">
                There is an error with autosave and your work is not being
                saved. Please contact support if this persists.
              </template>
            </p>
          </template>
          <template #primary-navigation>
            <breadcrumb>
              <breadcrumb-item
                :to="{
                  name: 'classes'
                }"
                >Classes
              </breadcrumb-item>
            </breadcrumb>
          </template>
          <template #page-action>
            <toggle
              v-if="splitViewAvailable"
              link
              :toggle="viewMode === 'split'"
              @toggle="toggleSplitView"
            >
              <template #on>Show Inline Question View</template>
              <template #off>Show Split Question View</template>
            </toggle>
          </template>
          <template #title>
            <div class="header-title">
              <sticky-header-title>
                {{ activity.name }}
              </sticky-header-title>
            </div>
          </template>
          <template #sub-title>
            <sticky-header-sub-title>
              <template v-if="!isGroupAssignment"
                >Individual Assignment</template
              >
              <activity-group-name v-if="isGroupAssignment" />
              <activity-grading-progress />
              <activity-score student />
            </sticky-header-sub-title>
          </template>
          <template #actions>
            <div class="action-buttons">
              <activity-autosave-timestamp :autosave-state="autosaveState" />
              <submit-button
                action="reOpenResponse"
                v-if="
                  !gradeAfterSubmit && isSubmitted && isReopen && !isOverdue
                "
              >
                <template #default>Reopen</template>

                <template #submitting> Opening </template>

                <template #submitted> Opened </template>
              </submit-button>
              <submit-button
                v-else
                action="submitForGrading"
                :disabled="(isSubmitted || isOverdue) && !isReopened"
              >
                <template #default>Submit for Grading</template>
                <template #submitting>Submitting</template>
                <template #submitted>Submitted</template>
              </submit-button>
            </div>
          </template>
        </sticky-header>
        <p v-if="isSubmitted && !isReopen">
          <strong>
            Because you have submitted this assignment for grading, you may not
            make any further changes to the assignment unless your instructor
            requests it.
          </strong>
        </p>
        <p v-else-if="isSubmitted && isReopen">
          <strong v-if="gradeAfterSubmit">
            This assignment can no longer be worked on because it was submitted
            and feedback was given on autograded questions.
          </strong>
          <strong v-else>
            This assignment was submitted, but you can reopen it to continue
            working up until the due date.
          </strong>
        </p>
        <p v-else-if="isReopened">
          <strong>
            This assignment has been reopened. You can modify your answers and
            re-submit for grading.
          </strong>
        </p>
        <p v-else-if="isOverdue">
          <strong>
            Your instructor stopped accepting responses for this assignment on
            {{
              $format.date(
                response.assignment.endDate,
                'MM/dd/yyyy [at] HH:mm a'
              )
            }}. Your response will not be saved.
          </strong>
        </p>
        <activity-objectives
          :variable-context="variableContext"
          :view-as-student="true"
        />
        <activity-sections
          v-slot="{ section, index }"
          :class="{
            'student-activity-sections': viewMode === 'split'
          }"
        >
          <activity-section
            class="student-activity-section"
            :section="section"
            :variable-context="variableContext"
            :view-as-student="true"
          >
            <activity-section-components
              v-slot="{ component }"
              :view-mode="viewMode"
              :split-view-component-enabled="splitViewComponentEnabled"
            >
              <activity-split-view-component-realtime
                v-if="component.componentType === 'SplitView'"
                :component="component"
                :variableContext="variableContext"
                :componentResponseMaps="componentResponseMaps"
                :yjsProvider="yjsProvider"
                view-as-student
                @change="updateResponse"
                :viewMode="viewMode"
              />
              <activity-section-component-realtime
                v-slot="{ onSubmit, onCanSubmitChange, canSubmit }"
                v-else
                :component="component"
                :component-response-map="componentResponseMaps[component._id]"
                @change="updateResponse"
              >
                <activity-autosave-indicator
                  :autosaved-components="autosavedComponents"
                />
                <activity-section-component-realtime-renderer
                  :variableContext="variableContext"
                  :componentResponseMap="componentResponseMaps[component._id]"
                  :yjsProvider="yjsProvider"
                  :canSubmit="canSubmit"
                  :component="component"
                  @submit="onSubmit"
                  @canSubmitChange="onCanSubmitChange"
                  view-as-student
                ></activity-section-component-realtime-renderer>
              </activity-section-component-realtime>
            </activity-section-components>
            <activity-section-lock
              :disabled="isSubmitted || isOverdue"
              :activityIndex="index"
              @lock="lockSection"
            />
          </activity-section>
        </activity-sections>
      </activity>
    </async-form>
  </loading-container>
</template>

<script>
import { mapState, useStore } from 'vuex'
import { AssignmentStudentFeedbackTimingValue } from '@pi/types'
import Activity from 'src/modules/activities/components/Activity'
import ActivityGroupName from 'src/modules/activities/components/ActivityGroupName'
import ActivityScore from 'src/modules/activities/components/ActivityScore'
import ActivityObjectives from 'src/modules/activities/components/ActivityObjectives'
import ActivitySections from 'src/modules/activities/components/ActivitySections'
import ActivitySection from 'src/modules/activities/components/ActivitySection'
import ActivitySectionComponents from 'src/modules/activities/components/ActivitySectionComponents'
import ActivitySectionComponentRealtime from 'src/modules/activities/components/ActivitySectionComponentRealtime'
import ActivitySectionComponentRealtimeRenderer from 'src/modules/activities/components/ActivitySectionComponentRealtimeRenderer'
import ActivitySplitViewComponentRealtime from 'src/modules/activities/components/ActivitySplitViewComponentRealtime'
import ActivitySectionLock from 'src/modules/activities/components/ActivitySectionLock'
import ActivityAutosaveIndicator from 'src/modules/activities/components/ActivityAutosaveIndicator'
import ActivityAutosaveTimestamp from 'src/modules/activities/components/ActivityAutosaveTimestamp'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'
import AlertModal from 'src/shared/components/modals/AlertModal'
import ActivityGradingProgress from 'src/modules/activities/components/ActivityGradingProgress.vue'
import SessionExpiredModal from 'src/modules/auth/modals/SessionExpiredModal'
import * as Y from 'yjs'
import { HocuspocusProvider } from '@hocuspocus/provider'
import client from 'src/shared/api-client'

const scheme = window.location.protocol.startsWith('https')
  ? 'secure'
  : 'insecure'
const websocketProtocol = scheme === 'secure' ? 'wss' : 'ws'
const PI_WEBSOCKET_URL = `${websocketProtocol}://${PI_WSS_HOST}/ws/v1`

export default {
  name: 'StudentResponseRealtimeView',
  components: {
    Activity,
    ActivityGradingProgress,
    ActivityGroupName,
    ActivityScore,
    ActivityObjectives,
    ActivitySections,
    ActivitySection,
    ActivitySectionComponents,
    ActivitySectionComponentRealtime,
    ActivitySectionComponentRealtimeRenderer,
    ActivitySplitViewComponentRealtime,
    ActivitySectionLock,
    ActivityAutosaveIndicator,
    ActivityAutosaveTimestamp
  },
  inject: ['$modal'],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup() {
    const store = useStore()
    const isReopen = store.getters['features/isEnabled']('reopen')
    return { isReopen }
  },
  data() {
    return {
      ydoc: null,
      activity: null,
      assignment: null,
      response: null,
      isSubmitted: false,
      isReopened: false,
      groupMembers: [],
      autosaveState: null,
      autosavedComponents: [],
      autogradedComponents: [],
      componentResponseMaps: {},
      yjsErrors: [],
      isLoading: true,
      isAutosaving: false,
      isMounted: false,
      connectionStatus: undefined,
      autosaveStatus: undefined
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    gradeAfterSubmit() {
      return (
        this.response.assignment.studentFeedbackTiming ===
        AssignmentStudentFeedbackTimingValue.AfterAssignmentSubmit
      )
    },
    hasAutosaveError() {
      return (
        ['disconnected', 'connecting'].includes(this.connectionStatus) ||
        this.autosaveStatus === 'failing'
      )
    },
    isOverdue() {
      const assignmentEndDate = this.response?.assignment?.endDate
        ? new Date(this.response.assignment.endDate)
        : undefined
      const extensionEndDate = this.response?.extensionEndDate
        ? new Date(this.response.extensionEndDate)
        : undefined

      const endDate =
        extensionEndDate &&
        (!assignmentEndDate || extensionEndDate > assignmentEndDate)
          ? extensionEndDate
          : assignmentEndDate

      return (
        endDate &&
        endDate < new Date() &&
        this.response?.gradingProgress !== 'feedback'
      )
    },
    isGroupAssignment() {
      return this.response.assignment?.assignedTo === 'groups'
    },
    splitViewComponentEnabled() {
      return this.activity.splitViewComponentEnabled
    },
    submitModalText() {
      if (!this.isReopen) {
        return 'Once you submit this assignment for grading, you will not be able to make any further changes unless your instructor requests them.'
      } else {
        switch (this.response.assignment.studentFeedbackTiming) {
          case AssignmentStudentFeedbackTimingValue.AfterQuestionSubmit:
            return 'After you submit this assignment for grading, you will still be able to reopen the assignment to continue working.'
          case AssignmentStudentFeedbackTimingValue.AfterFinalGrade:
            return 'Once you submit this assignment for grading, you will receive feedback on your work and will not be able to make further changes.'
          case AssignmentStudentFeedbackTimingValue.AfterAssignmentSubmit:
            return 'Once you submit this assignment for grading, you will receive feedback on your work and will not be able to make further changes.'
          case AssignmentStudentFeedbackTimingValue.Never:
            return 'Once you submit this assignment for grading you will not be able to make further changes.'
          default:
            return 'Once you submit this assignment for grading you will not be able to make further changes.'
        }
      }
    }
  },
  methods: {
    async load() {
      this.isLoading = true
      try {
        const [response, activity] = await Promise.all([
          client.assignments.getStudentResponse({ assignmentId: this.id }),
          client.assignments.getActivity({ assignmentId: this.id })
        ])

        this.activity = activity

        this.response = {
          ...response,
          owner: this.user
        }
        this.assignment = response.assignment

        this.autogradedComponents = this.activity.sections.reduce(
          (acc, section) => {
            acc = acc.concat(
              section.components
                .filter(
                  c =>
                    (c.componentType === 'MultipleChoiceQuestion' ||
                      c.componentType === 'NumericalQuestion' ||
                      c.componentType === 'DragDropQuestions') &&
                    c.autograde
                )
                .map(c => c._id)
            )
            return acc
          },
          []
        )

        this.connectToHocuspocus()
      } catch (e) {
        this.isLoading = false
        throw e
      }
    },
    async connectToHocuspocus() {
      const documentId = `assignmentresponse/${this.response.id}`
      this.ydoc = new Y.Doc()

      this.ydoc.getArray('lockedSections').observe(() => {
        this.response.lockedSections = this.ydoc
          .getArray('lockedSections')
          .toArray()
      })

      this.ydoc.getMap('metadata').observeDeep(events => {
        const newProgress = this.ydoc.getMap('metadata').get('activityProgress')
        const gradingProgress = this.ydoc
          .getMap('metadata')
          .get('gradingProgress')
        const isSubmitted = newProgress === 'submitted'

        const isUnavailable = newProgress === 'unavailable'
        const variables = this.ydoc.getMap('metadata').get('variables')
        const showScore = this.ydoc.getMap('metadata').get('showScore')
        const showFeedback = this.ydoc.getMap('metadata').get('showFeedback')

        this.response.variables = variables
        this.response.assignment.showScore = showScore
        this.response.assignment.showFeedback = showFeedback

        if (isUnavailable) {
          this.$error(
            'You do not have access to this assignment. Please contact your instructor.'
          )
          this.$router.push({ name: 'home' })
          return
        }

        const activityProgressChanged = events.some(event => {
          const change = event.changes.keys.get('activityProgress')
          return change?.action === 'update' && change?.oldValue !== newProgress
        })
        if (isSubmitted && activityProgressChanged) {
          this.$success('Response successfully submitted for grading.')
        }

        const errorState = this.ydoc
          .getMap('metadata')
          .get('autosave')
          .get('error')
        this.autosaveStatus = errorState ? 'failing' : 'ok'
        this.autosaveState = this.ydoc.getMap('metadata').get('autosave')
        this.isSubmitted = isSubmitted
        this.isReopened = gradingProgress === 'reopened'
        this.groupMembers = this.ydoc.getMap('metadata').get('students')
        this.isLoading = !this.ydoc.getMap('metadata').get('responseLoaded')
      })

      this.ydoc.getArray('errors').observe(() => {
        const errors = this.ydoc.getArray('errors').toJSON()

        this.yjsErrors = this.yjsErrors.filter(
          error => Date.now() - error.occurredAt < 10000
        )

        for (const error of errors) {
          if (
            this.yjsErrors.find(
              e => (e.name ?? e.message) === (error.name ?? error.message)
            )
          ) {
            continue
          }

          this.yjsErrors.push(error)
          this.$error(error.message)
        }
      })

      this.ydoc.getArray('autosavedComponentIds').observe(() => {
        this.autosavedComponents = this.ydoc
          .getArray('autosavedComponentIds')
          .toArray()
      })

      this.ydoc.getMap('responses').observe(() => {
        const responses = this.ydoc.getMap('responses')
        const componentResponseMaps = {}
        for (const [componentId, response] of responses) {
          componentResponseMaps[componentId] = response
        }
        this.componentResponseMaps = componentResponseMaps
      })

      // TODO replace this with observers on individual response components
      this.ydoc.getMap('responses').observeDeep(() => {
        const responses = this.ydoc.getMap('responses')

        for (const [componentId, response] of responses.entries()) {
          const respIndex = this.response.responses.findIndex(
            resp => resp.component === componentId
          )

          if (respIndex < 0) {
            this.response.responses.push({
              ...response.toJSON(),
              component: componentId
            })
          } else {
            this.response.responses.splice(respIndex, 1, {
              ...response.toJSON(),
              component: componentId
            })
          }
        }
      })

      this.yjsProvider = new HocuspocusProvider({
        url: PI_WEBSOCKET_URL,
        name: documentId,
        document: this.ydoc,
        token: '_', // This triggers the onAuthenticate hook on the server, where we use the request cookies to authenticate the user.
        onAuthenticationFailed: async ({ reason }) => {
          if (reason === 'no-user') {
            await this.$modal.show(SessionExpiredModal)
            await this.yjsProvider.connect()
          } else {
            this.$error(`Co-Lab authentication failed: ${reason}`)
            this.$router.push({ name: 'home' })
          }
        }
      })

      this.yjsProvider.on('status', ({ status }) => {
        // Only update the status after the first connection attempt.
        if (this.connectionStatus || status !== 'connecting') {
          this.connectionStatus = status
        }
      })
    },
    async submit(e) {
      if (this.ydoc.getMap('metadata').get('submitting')) {
        e.done()
        return
      }

      try {
        if (e.action === 'submitForGrading') {
          const { status } = await this.$modal.show(ConfirmModal, {
            text: this.submitModalText,
            prompt:
              'Are you sure you want to submit this assignment for grading?'
          })

          if (status !== 'ok') {
            e.done(false)
            return
          }

          const metadata = this.ydoc.getMap('metadata')
          metadata.set('submitting', true)

          e.done()
        } else if (e.action === 'reOpenResponse') {
          const metadata = this.ydoc.getMap('metadata')
          metadata.set('unsubmitting', true)

          e.done()
        }
      } catch (error) {
        e.done(false)
        throw error
      }
    },
    updateResponse({ response, isDirty }) {
      if (this.isSubmitted || this.isOverdue) {
        return
      }

      this.ydoc
        .getMap('responses')
        .get(response.component)
        .set('value', response.value)
    },
    async lockSection(index) {
      this.ydoc.getArray('lockedSections').push([index])
    }
  },
  watch: {
    groupMembers: async function (value) {
      // check if authed student is not in the group
      if (!value.includes(this.user.id)) {
        // the authed user has been removed from the grouo. show modal warning
        await this.$modal.show(AlertModal, {
          title: `You have been removed from group: ${this.response.groupName}`,
          body: 'You will now be taken back to your assignments list.'
        })

        this.$router.push('/')
        return
      }

      // get students who have been removed from group
      const studentsRemoved = this.response.students.filter(
        student => !value.includes(student.id)
      )

      // get students who have been added to the group
      const addedStudentIds = value.filter(
        studentId => !this.response.students.map(s => s.id).includes(studentId)
      )

      if (addedStudentIds.length || studentsRemoved.length) {
        // if students have been added or removed we need to reload the response.
        const response = await client.assignments.getStudentResponse({
          assignmentId: this.id
        })

        // populate the names of the students who have been added.
        const studentsAdded = response.students.filter(s =>
          addedStudentIds.includes(s.id)
        )

        const addedMessage = studentsAdded.length
          ? `<h4>The following students have been added to your group:</h4><p>${studentsAdded
              .map(s => `${s.firstName} ${s.lastName}`)
              .join(', ')}</p>`
          : ''
        const removedMessage = studentsRemoved.length
          ? `<h4>The following students have been removed from your group:</h4><p>${studentsRemoved
              .map(s => `${s.firstName} ${s.lastName}`)
              .join(', ')}</p>`
          : ''

        await this.$modal.show(AlertModal, {
          title: 'Attention. Group Members Have Changed.',
          body: '',
          html: `${addedMessage} ${removedMessage}<br/>`.trim()
        })

        this.response = {
          ...response,
          owner: this.user
        }
      }
    }
  },
  async mounted() {
    await this.load()
  },
  async beforeUnmount() {
    this.ydoc?.destroy()
    this.yjsProvider?.destroy()
  }
}
</script>

<style lang="scss" scoped>
.response__autosave-error {
  background: $color-error;
  font-weight: bold;
  margin: -4px -9999rem 4px;
  padding: 4px 9999rem;
}

.form-button:not(.modal-button-submit):not(.form-button--link) + .form-button {
  margin-right: 0;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  & .form-button--secondary {
    margin-right: 6px;
  }

  @media (min-width: $screen-sm) {
    margin-left: 0px !important;
  }

  @media (min-width: $screen-md) {
    margin-left: auto !important;
  }

  @media (min-width: $screen-lg) {
    margin-left: auto !important;
  }
}

.submission-note {
  margin-bottom: 0;
  font-size: 12px;
}

.student-activity-sections {
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-right: -50vw;
  margin-left: -50vw;
  padding-left: 30px;
  padding-right: 30px;
}
</style>
